import {
	Divider,
	Flex,
	IconV2,
	Section,
	Theme,
	makeStyles,
	useMediaQuery,
} from '@bamboohr/fabric';
import ApplicationStateButtons from 'base/careers/shared/components/application-state-buttons';
import { CareerSiteContext } from 'base/careers/shared/components/career-site-provider';
import { CareerAppStatus, FullPosition } from 'base/careers/shared/types';
import { getLocationDisplay } from 'hiring/utils';
import { noop, throttle } from 'lodash';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { Link } from 'react-router-dom';
import { CareerFormData } from '../../shared/types';
import CareerApplicationForm from './career-application-form';
import { PositionInfoList } from './position-info/position-info-list';
import responsiveFormStyles from './responsiveFormStyles';
import SuccessContent from './success-content';

interface StyleProps {
	careerAppStatus: CareerAppStatus;
	formRef: any;
}

const getTransformationState = (careerAppStatus: CareerAppStatus): string => {
	switch (careerAppStatus) {
		case CareerAppStatus.visible:
		case CareerAppStatus.submitting:
			return 'translateX(calc(-100% - 4px))'; // Account for grid gap
		case CareerAppStatus.hidden:
			return 'translateX(0)';
		case CareerAppStatus.submitted:
			return 'translateX(calc(-200% - 8px))'; // Account for grid gap
		default:
			return 'translateX(0)';
	}
};

const getContentHeight = (careerAppStatus: CareerAppStatus, formRef: any): object => {
	const FORM = formRef.current?.children;
	let newHeight = 225;

	switch (careerAppStatus) {
		case CareerAppStatus.visible:
		case CareerAppStatus.submitting:
			if (FORM?.applicationFormWrapper?.clientHeight < newHeight) {
				break;
			}
			return FORM?.applicationFormWrapper?.clientHeight < FORM?.descriptionWrapper?.clientHeight
				? { height: FORM?.applicationFormWrapper?.clientHeight + 'px' }
				: {};
		case CareerAppStatus.hidden:
			newHeight = FORM?.descriptionWrapper?.clientHeight > newHeight ? FORM?.descriptionWrapper?.clientHeight : newHeight;
			break;
		case CareerAppStatus.submitted:
			newHeight = FORM?.successWrapper?.clientHeight > newHeight ? FORM?.successWrapper?.clientHeight : newHeight;
			break;
		default:
			newHeight = FORM?.descriptionWrapper?.clientHeight > newHeight ? FORM?.descriptionWrapper?.clientHeight : newHeight;
			break;
	}

	return { height: newHeight + 'px' };
};

const styles = makeStyles<Theme, StyleProps>(({ breakpoints, typography, palette }) => ({
	backText: {
		marginLeft: '5px',
	},
	backLink: {
		display: 'block',
		color: palette.gray[700],
		fill: palette.gray[700],
		'&:hover': palette.primary.main,
		marginBottom: '16px',
		fontSize: typography.small.fontSize,
	},
	jobTitle: {
		color: palette.primary.main,
		fontSize: typography.h3.fontSize,
		margin: 0,
	},
	subtitle: {
		color: palette.gray[800],
		fontWeight: typography.fontWeightRegular,
		fontSize: typography.small.fontSize,
		display: 'block',
		marginBottom: '24px',
		[breakpoints.down(768)]: {
			fontSize: typography.h4.fontSize,
			margin: 0,
		},
	},
	buttonWrapper: {
		[breakpoints.down(772)]: {
			marginTop: '16px',
		},
		[breakpoints.up(992)]: {
			display: 'none',
		},
	},
	contentSection: ({ careerAppStatus }) => ({
		display: 'grid',
		gridTemplateColumns: '100% 100% 100%',
		transform: getTransformationState(careerAppStatus),
		transition: 'all 0.3s ease-in-out',
	}),
	contentWrapper: ({ careerAppStatus, formRef }) => ({
		paddingBottom: '14px',
		position: 'relative',
		overflow: 'hidden',
		...getContentHeight(careerAppStatus, formRef),
	}),
	applicationFormWrapper: {
		paddingLeft: '6px',
		background: 'transparent',
		height: 'fit-content',
		width: '100%',
		gridColumn: '2 / 3',
		gridRow: '1',
		[breakpoints.down(800)]: {
			paddingLeft: '4px',
		},
	},
	applicationFormTitle: {
		margin: '9px 0',
	},
	successWrapper: {
		background: 'transparent',
		height: 'fit-content',
		width: '100%',
		gridColumn: '3 / 4',
		gridRow: '1',
	},
	descriptionWrapper: {
		background: 'transparent',
		gridColumn: '1 / 2',
		gridRow: '1',
		display: 'flex',
		height: 'fit-content',
		wordBreak: 'break-word',
		marginTop: '20px',
	},
	description: {
		marginBottom: '16px',
		width: '100%',
	},
	bottomButtonWrapper: ({ careerAppStatus }) => ({
		margin: '0 auto',
		width: '100%',
		display: careerAppStatus === CareerAppStatus.visible ? 'none' : 'block',
		[breakpoints.up(992)]: {
			display: 'none',
		},
	}),
	buttonTooltipWrap: {
		marginRight: '12px',
	},
}));

export default function MainContentCard({
	position,
	formFields,
	backUrlString,
}: {
	position: FullPosition;
	formFields: CareerFormData;
	backUrlString: string;
}): React.ReactElement {
	const formRef = useRef(null);
	const { careerAppStatus, setAppStatus } = useContext(CareerSiteContext);

	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const classes = styles({ careerAppStatus, formRef });
	const responsiveForm = responsiveFormStyles();
	const smallAndUp = useMediaQuery('(min-width: 768px)');
	const mediumAndUp = useMediaQuery('(min-width: 992px)');

	useEffect(() => {
		window.addEventListener(
			'resize',
			throttle(() => {
				forceUpdate();
			}, 250)
		);
		// Ensure we trigger at least one resize event.
		window.dispatchEvent(new Event('resize'));
		return () => {
			setAppStatus(CareerAppStatus.hidden);
			window.removeEventListener('resize', throttle(noop));
		};
	}, []);

	const locationDisplay = getLocationDisplay(
		position.locationType,
		position.location?.city || position.atsLocation?.city,
		position.location?.state || position.atsLocation?.state,
		position.location?.addressCountry || position.atsLocation?.country,
		position?.departmentLabel
	);
	return (
        <Section>
			<Link className={classes.backLink} to={backUrlString}>
				<Flex alignItems='center'>
					<IconV2 name='chevron-left-regular' size={16} />
					<span className={classes.backText}>Job Openings</span>
				</Flex>
			</Link>
			<Flex flexDirection={smallAndUp ? 'row' : 'column'} justifyContent='space-between'>
				<div>
					<h3 className={classes.jobTitle}>{position.jobOpeningName}</h3>
					<span className={classes.subtitle}>{`${locationDisplay}`}</span>
				</div>
				<div className={classes.buttonWrapper}>
					<ApplicationStateButtons fullWidth={!smallAndUp} />
				</div>
			</Flex>
			{!mediumAndUp && <PositionInfoList divider={false} horizontal={smallAndUp} position={position} smallRow={!smallAndUp} />}
			<Divider light={true} marginBottom='12px' />
			<div className={classes.contentWrapper}>
				<div className={classes.contentSection} ref={formRef}>
					<div className={classes.descriptionWrapper} id='descriptionWrapper'>
						<div
							className={`BambooRichText ${classes.description}`}
							dangerouslySetInnerHTML={{
								__html: position.description,
							}}
						/>
					</div>
					<div className={`${classes.applicationFormWrapper} ${responsiveForm.form}`} id='applicationFormWrapper'>
						<CareerApplicationForm formFields={formFields} />
					</div>
					<div className={classes.successWrapper}>
						<SuccessContent />
					</div>
				</div>
			</div>
			<div className={classes.bottomButtonWrapper}>
				<ApplicationStateButtons fullWidth={true} />
			</div>
		</Section>
    );
}
