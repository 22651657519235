import { Divider, Flex, makeStyles, Theme } from "@bamboohr/fabric";
import { ReactElement } from "react";

interface StyleProps {
    smallRow: boolean;
}

const styles = makeStyles<Theme, StyleProps>(({ palette, typography }) => ({
    header: props =>  ({
        fontSize: props.smallRow ? typography.medium.fontSize : typography.small.fontSize,
        color: palette.gray[700],
        marginRight: props.smallRow ? "4px" : "0",
    }),
    infoText: props =>  ({
        fontWeight: props.smallRow ? typography.fontWeightSemibold : typography.fontWeightMedium,
        paddingBottom: '8px',
    }),
}));

export function PositionInfoRow({ header, text, divider, smallRow }: { header: string; text: string, divider: boolean, smallRow: boolean }): ReactElement {
    const classes = styles({ smallRow });

    return (
        <Flex flexDirection={smallRow ? "row" : "column"} paddingTop='8px'>
            <p className={classes.header}>{header}</p>
            <p className={classes.infoText}>{text}</p>
            {divider  && <Divider light={true} />}
        </Flex>
    );
}