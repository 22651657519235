import { Flex, IconV2, LayoutBox, makeStyles, useMediaQuery } from '@bamboohr/fabric';
import { getLocationFromObjectForDisplay } from 'hiring/utils';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { JobOpeningLocationTypes, Position } from '../../shared/types';

const styles = makeStyles(({ breakpoints, typography, palette }) => ({
	positionName: {
		fontSize: typography.medium.fontSize,
		marginBottom: '8px',
		marginLeft: '16px',
		[breakpoints.up(992)]: {
			marginBottom: 0,
		},
	},
	icon: {
		fill: palette.gray[600],
		marginTop: '4px',
		marginRight: '7px',
	},
	departmentLabel: {
		color: palette.gray[700],
		fontSize: typography.teenie.fontSize,
		marginLeft: '16px',
	},
	detailTop: {
		color: palette.gray[900],
		fontSize: typography.medium.fontSize,
	},
	detailBottom: {
		fontSize: typography.teenie.fontSize,
		color: palette.gray[700],
	},
	textSection: {
		[breakpoints.up(768)]: {
			width: '220px',
		},
		[breakpoints.down(768)]: {
			width: '40vw',
		},
	},
}));

export default function JobOpeningRow({ position }: { position: Position }): React.ReactElement {
	const classes = styles();
	const mediumAndUp = useMediaQuery('(min-width: 992px)');

	const { bottomText, Icon, topText } = useMemo(() => {
		const { locationType, location, atsLocation } = position;
		switch (locationType) {
			case JobOpeningLocationTypes.IN_OFFICE:
				return {
					Icon: <IconV2 color='neutral-extra-extra-strong' name='location-dot-regular' size={16} />,
					topText: location?.city,
					bottomText: location?.state,
				};
			case JobOpeningLocationTypes.REMOTE:
				return {
					Icon: <IconV2 color='neutral-extra-extra-strong' name='desktop-regular' size={16} />,
					topText: $.__('Remote'),
					bottomText: getLocationFromObjectForDisplay(locationType, atsLocation),
				};
			case JobOpeningLocationTypes.HYBRID:
				return {
					Icon: <IconV2 color='neutral-extra-extra-strong' name='house-building-regular' size={16} />,
					topText: $.__('Hybrid'),
					bottomText: getLocationFromObjectForDisplay(locationType, location),
				};
			default:
				return {};
		}
	}, [position]);

	const queryParams = new URLSearchParams(location.search);
	const source = queryParams.get('source') || '';
	const queryString = source ? `?source=${source}` : '';

	return (
		<Flex
			alignItems={mediumAndUp ? 'center' : 'flex-start'}
			flexDirection={mediumAndUp ? 'row' : 'column'}
			justifyContent='space-between'
			paddingRight='64px'
			paddingY='32px'
		>
			<Flex flexDirection='column' minWidth='33%'>
				<Link className={classes.positionName} to={`/careers/${position.id}${queryString}`}>
					{position.jobOpeningName}
				</Link>
				<div className={classes.departmentLabel}>{position.departmentLabel}</div>
			</Flex>
			<Flex justifyContent='space-around' minWidth='67%' paddingTop={mediumAndUp ? '0px' : '16px'}>
				{position.locationType && (
					<Flex alignItems='flex-start'>
						<LayoutBox marginRight='7px' marginTop='4px'>
							{Icon}
						</LayoutBox>
						<div className={classes.textSection}>
							<p className={classes.detailTop}>{topText}</p>
							<p className={classes.detailBottom}>{bottomText}</p>
						</div>
					</Flex>
				)}
				<Flex alignItems='flex-start'>
					<LayoutBox marginRight='7px' marginTop='4px'>
						<IconV2 color='neutral-extra-extra-strong' name='user-group-regular' size={16} />
					</LayoutBox>
					<div className={classes.textSection}>
						<p className={classes.detailTop}>{position.departmentLabel}</p>
						<p className={classes.detailBottom}>{position.employmentStatusLabel}</p>
					</div>
				</Flex>
			</Flex>
		</Flex>
    );
}
