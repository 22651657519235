export interface Company {
	name: string;
	id: number;
	logoUrl: string;
	careerShareUrl: string;
	shareImageUrl: string;
	inTrial: boolean;
	appSessId: string;
}

export enum CareersSiteErrors {
	INVALID_DATA = 'invalid_data',
}

export enum JobOpeningLocationTypes {
	IN_OFFICE = '0',
	REMOTE = '1',
	HYBRID = '2',
}

export interface Position {
	atsLocation: {
		city: string;
		country: string;
		province: string;
		state: string;
	};
	id: number;
	jobOpeningName: string;
	departmentId: number;
	departmentLabel: string;
	employmentStatusLabel: string;
	locationType: JobOpeningLocationTypes;
	location: {
		city: string;
		state: string;
	};
}

export interface CareerData {
	company: Company;
	positions: Position[];
	trackingSource: string;
	response: string;
}

interface FormField {
	isRequired: boolean;
	value: string;
	options?: string[];
	fileName?: string;
}

interface FormFile {
	value: string;
	fileName: string;
}

interface CustomQuestion {
	id: string;
	isRequired: boolean;
	question: string;
	type: string;
	value: string;
	isValueOther: boolean;
	hasOther: boolean;
	options: string[];
	files: FormFile[];
}
export interface CareerFormData {
	appLocation: string;
	sourceId: string;
	firstName: FormField;
	lastName: FormField;
	email: FormField;
	phone: FormField;
	streetAddress?: FormField;
	city?: FormField;
	state?: FormField;
	zipcode?: FormField;
	countryId?: FormField;
	resumeFieldId?: FormField;
	desiredPay?: FormField;
	twitterUsername?: FormField;
	coverLetterFileId?: FormField;
	educationLevelId?: FormField;
	linkedInUrl?: FormField;
	referredBy?: FormField;
	educationInstitutionName?: FormField;
	dateAvailable?: FormField;
	websiteUrl?: FormField;
	references?: FormField;
	customQuestions?: CustomQuestion[];
	jobCategoryId?: string;
}

export interface FullPosition {
	atsLocation: {
		city: string;
		country: string;
		countryId: string;
		state: string;
	};
	id: number;
	jobOpeningShareUrl: string;
	jobOpeningName: string;
	jobOpeningStatus: string;
	departmentId: number;
	departmentLabel: string;
	employmentStatusLabel: string;
	location: {
		city: string;
		state: string;
		streetAddress: string;
		postalCode: string;
		addressCountry: string;
	};
	description: string;
	compensation: string;
	datePosted: string;
	minimumExperience: string;
	jobCategoryId?: string;
	locationType: JobOpeningLocationTypes;
}

export interface CareerPageData {
	jobOpening: FullPosition;
	formFields: CareerFormData;
}

export interface CompanyInfo {
	name: string;
	id: number;
	logoUrl: string;
	careerShareUrl: string;
	shareImageUrl: string;
	inTrial: boolean;
	appSessId: string;
}

export enum CareerAppStatus {
	visible,
	hidden,
	submitted,
	submitting
}

export interface CareerSiteContextValue {
    companyInfo: CompanyInfo;
	careerAppStatus: CareerAppStatus;
	toggleAppVisibility: () => void;
	setAppStatus: (status: CareerAppStatus) => void;
}

export interface JobPostingSchema {
	"@context": string;
	"@type": string;
	title: string;
	description: string;
	identifier: {
		"@type": string;
		name: string;
		value: number;
	};
	datePosted: string;
	employmentType: string; // enum look at this
	hiringOrganization: {
		"@type": string;
		name: string;
		logo: string;
	};
	jobLocation: {
		"@type": string;
		address: {
			"@type": string;
			addressLocality: string;
			addressRegion: string;
			postalCode: string;
			addressCountry: string;
		};
	};
	url: string;
}
