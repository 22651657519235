import { ReactElement, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { requestCareerList } from '../../shared/utils';
import { Position } from 'base/careers/shared/types';
import { makeStyles, createStyles, useMediaQuery, Loader, Flex, Typography } from '@bamboohr/fabric';
import { CareerSiteContext } from 'base/careers/shared/components/career-site-provider';
import { ResponsiveContentWrapper } from 'base/careers/shared/components/responsive-content-wrapper';
import { ShareButton } from 'base/careers/shared/social/share-button';
import { ShareType } from 'base/careers/shared/social/types';
import CurrentOpeningsCard from './current-openings-card';
import BlankPostingCard from 'base/careers/shared/components/blank-posting-card';
import { CareerSiteFooter } from 'base/careers/shared/components/footer';

export const sharedStyles = makeStyles((theme) => {
	const { breakpoints } = theme;
	return createStyles({
		loadingBackground: {
			display: 'flex',
			alignItems: 'center',
			minHeight: '100vh',
		},
		imgContainer: {
			display: 'flex',
			maxHeight: '60px',
			maxWidth: '300px',
			height: 'auto',
			width: 'auto',
		},
		logoImg: {
			objectFit: 'contain',
			objectPosition: 'left',
			[breakpoints.down(768)]: {
				objectPosition: 'center',
				maxWidth: 'inherit',
				maxHeight: 'inherit',
			},
		},
	});
});

const styles = makeStyles((theme) => {
	const { breakpoints } = theme;

	return createStyles({
		background: {
			backgroundColor: theme.palette.gray[50],
			minHeight: '100vh',
			borderRadius: '16px',
			[breakpoints.up(768)]: {
				margin: '32px',
			},
			[breakpoints.down(768)]: {
				margin: '',
			},
		},
		header: {
			padding: '24px 0',
			[breakpoints.up(992)]: {
				padding: '40px 0',
			},
		},
		footerWrapper: {
			background: theme.palette.background.default,
			marginTop: '100px',
		},
		shareIcons: {
			display: 'none',
			[breakpoints.up(768)]: {
				display: 'flex',
			},
		},
	});
});

export function CareersHome(): ReactElement {
	const [positions, setPositions] = useState<Position[] | null>(null);
	const smallAndUp = useMediaQuery('(min-width: 768px)');
	const { companyInfo } = useContext(CareerSiteContext);

	const classes = styles();
	const sharedClasses = sharedStyles();

	useEffect(() => {
		requestCareerList().then((data) => setPositions(data));
	}, []);

	if (!positions || !companyInfo) {
		return (
			<div className={sharedClasses.loadingBackground}>
				<Loader />
			</div>
		);
	}

	return (
        <Flex className={classes.background} flexDirection='column' justifyContent='flex-start'>
            <ResponsiveContentWrapper>
				<header className={classes.header}>
					<Flex alignItems='center' justifyContent={smallAndUp ? 'space-between' : 'center'}>
						{companyInfo.logoUrl ? (
							<Link className={sharedClasses.imgContainer} to='/careers'>
								<img alt={companyInfo.name} className={sharedClasses.logoImg} src={companyInfo.logoUrl} />
							</Link>
						) : (
							<Typography variant='h1'>{companyInfo.name}</Typography>
						)}
						<Flex className={classes.shareIcons}>
							<ShareButton shareType={ShareType.linkedin} title={companyInfo.name} viewPositionUrl={companyInfo.careerShareUrl} />
							<ShareButton
								shareText={`Take a look at the current openings at ${companyInfo.name}`}
								shareType={ShareType.twitter}
								title={companyInfo.name}
								viewPositionUrl={companyInfo.careerShareUrl}
							/>
							<ShareButton shareType={ShareType.facebook} title={companyInfo.name} viewPositionUrl={companyInfo.careerShareUrl} />
						</Flex>
					</Flex>
				</header>
				<main>{positions.length === 0 ? <BlankPostingCard showLink={false} /> : <CurrentOpeningsCard positions={positions} />}</main>
			</ResponsiveContentWrapper>
            <CareerSiteFooter />
        </Flex>
    );
}
