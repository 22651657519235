import { Ajax } from '@bamboohr/utils';
import { CareerSiteContext } from 'base/careers/shared/components/career-site-provider';
import Form from 'hiring/candidate-form.react';
import { ReactElement, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
	CareerAppStatus,
	CareerFormData,
	CareersSiteErrors,
} from '../../shared/types';

const FORM_ID = 'careerApplicationForm';

type responseData = {
	data: {
		success?: string | boolean;
		error?: string;
	};
	details?: string;
	status: number;
	statusText: string;
};

type errorResponse = {
	response: {
		data: {
			details: string;
			title?: string;
			type?: CareersSiteErrors;
		};
	};
	status: number;
	statusText: string;
};

type setStatus = (status: CareerAppStatus) => void;

export const handleSuccessResponse = (data: responseData, setAppStatus: setStatus) => {
	if (data.status === 200 && data.data.success !== false) {
		setAppStatus(CareerAppStatus.submitted);
	} else if (data.data.success === false && data.data.error) {
		window.setMessage(data.data.error, 'error');
		setAppStatus(CareerAppStatus.visible);
	} else {
		setAppStatus(CareerAppStatus.visible);
		window.setMessage(
			$.__(
				'Uh oh, something went wrong. Please try again or contact support@bamboohr.com',
			),
			'error',
		);
	}
};

export const handleFailureResponse = (
	error: errorResponse,
	setAppStatus: setStatus,
) => {
	const {
		response: { data },
	} = error;

	if (data) {
		window.setMessage(data?.details, 'error');
	} else {
		window.setMessage(
			$.__(
				'Uh oh, something went wrong. Please try again or contact support@bamboohr.com',
			),
			'error',
		);
	}
	setAppStatus(CareerAppStatus.visible);
	if (!!data?.type && data?.type === CareersSiteErrors.INVALID_DATA) {
		return;
	}
	window.Rollbar.error(
		`[ATS FE]: Careers site ${
			data?.title ?? data?.type ?? data?.details ?? ''
		}`,
		data,
	);
};

export default function CareerApplicationForm({
	formFields,
}: {
	formFields: CareerFormData;
}): ReactElement {
	const { setAppStatus, companyInfo } = useContext(CareerSiteContext);
	const { id } = useParams<{ id: string }>();

	const cancelAction = () => {
		setAppStatus(CareerAppStatus.hidden);
	};

	const primaryAction = () => {
		setAppStatus(CareerAppStatus.submitting);
		const FORM: HTMLFormElement = document.querySelector(`#${FORM_ID}`);
		const careerFormData = new FormData(FORM);

		// Specific value that BE is looking for for anit-spam
		const VALSTRING = `Set ${careerFormData.get(
			'firstName',
		)} ${careerFormData.get('lastName')} free`;
		const VALUE: any = window.btoa(VALSTRING.replace(/[^a-zA-Z0-9 -]/g, ''));
		const HASH: any = new Date().getHours() * companyInfo.id;
		careerFormData.set('displayName', VALUE);
		careerFormData.set('applicantHash', companyInfo.appSessId);
		careerFormData.set('positionHash', HASH);

		Ajax.post(`/careers/${id}/add`, careerFormData)
			.then((data) => handleSuccessResponse(data, setAppStatus))
			.catch((error) => handleFailureResponse(error, setAppStatus));
	};

	return (
		<form id="careerApplicationForm">
			<Form
				actions={{ cancelAction, primaryAction }}
				fields={formFields}
				formId="careerApplicationForm"
			/>
		</form>
	);
}
