import { Button } from '@bamboohr/fabric';
import { ReactElement } from 'react';

export const ViewJobDescriptionButton = ({ onClick }: { onClick: () => void }): ReactElement => {
	return (
        <Button color='secondary' onClick={onClick} size='biggie' startIcon='chevron-left-regular' type='button' width={100}>
			{$.__('View Job Description')}
		</Button>
    );
};
