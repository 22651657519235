import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { CareerFormData, CareerPageData, CareerAppStatus } from 'base/careers/shared/types';
import { getJobPostingSchema, requestPositionData } from 'base/careers/shared/utils';
import {
	ActionFooter,
	Button,
	Flex,
	IconV2,
	LayoutBox,
	Loader,
	makeStyles,
	Svg,
	TextButton,
	Theme,
	Tooltip,
	Typography,
} from '@bamboohr/fabric';
import { ApplyCard } from './apply-card';
import { ResponsiveContentWrapper } from 'base/careers/shared/components/responsive-content-wrapper';
import { PositionInfoList } from './position-info/position-info-list';
import { CareerSiteContext } from 'base/careers/shared/components/career-site-provider';
import { useLocation, useParams, Link } from 'react-router-dom';
import BlankPostingCard from 'base/careers/shared/components/blank-posting-card';
import MainContentCard from './main-content-card';
import { CareerSiteFooter } from 'base/careers/shared/components/footer';

import { sharedStyles } from '../careers-home';

const inTrialTooltip = $.__('Applications cannot be submitted for trial accounts.');
interface StyleProps {
	careerAppStatus: CareerAppStatus;
}

const styles = makeStyles<Theme, StyleProps>(({ breakpoints, palette, typography }) => ({
	background: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: palette.gray[50],
		minHeight: '100vh',
		minWidth: '400px',
		position: 'relative',
		overflow: 'hidden',
		[breakpoints.up(768)]: {
			borderRadius: '16px',
			margin: '32px',
		},
		[breakpoints.down(768)]: {
			borderRadius: 0,
			margin: '0px',
		},
	},
	wrapper: {
		backgroundColor: palette.background.paper,
		minHeight: '100%',
		width: '1170px',
		margin: 'auto',
		marginTop: 0,
		paddingBottom: '100px',
	},
	header: {
		padding: '24px 0',
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		[breakpoints.up(768)]: {
			display: 'block',
		},
		[breakpoints.up(992)]: {
			padding: '40px 0',
		},
	},
	descriptionWrapper: {
		maxWidth: '100%',
		flex: '1',
		[breakpoints.up(992)]: {
			width: '66.66%',
		},
		[breakpoints.up(1200)]: {
			maxWidth: '100%',
		},
	},
	sidebarWrapper: {
		display: 'none',
		position: 'sticky',
		flex: '0 1 360px',
		top: '20px',
		paddingLeft: '32px',
		minWidth: '280px',
		[breakpoints.up(992)]: {
			display: 'block',
		},
	},
	mainContent: {
		position: 'relative',
		maxWidth: '100%',
	},
	privacyWrapper: {
		position: 'absolute',
		display: 'none',
		bottom: '18px',
		right: '18px',
		[breakpoints.up(992)]: {
			display: 'block',
		},
	},
	privacyLink: {
		color: palette.gray[500],
		fontSize: typography.teenie.fontSize,
		marginLeft: '6px',
		'&:hover': palette.info.main,
	},
	privacyIcon: {
		fill: palette.gray[500],
	},
	main: {
		[breakpoints.up(768)]: {
			paddingBottom: '32px',
		},
	},
	actionFooterWrapper: {
		position: 'sticky',
		width: '100%',
		zIndex: '999',
	},
	buttonTooltipWrap: {
		marginRight: '12px',
	},
}));

export function CareerPage(): ReactElement {
	const [positionData, setPositionData] = useState<CareerPageData | null>(null);
	const { careerAppStatus, companyInfo } = useContext(CareerSiteContext);
	const { id } = useParams<{ id: string }>();
	const location = useLocation();
	const sharedClasses = sharedStyles();
	const queryParams = new URLSearchParams(location.search);
	const source = queryParams.get('source') || '';
	const { inTrial } = companyInfo || { inTrial: false };
	const classes = styles({ careerAppStatus });

	useEffect(() => {
		requestPositionData({ id: parseInt(id) }, source).then((data) => setPositionData(data));
	}, [id]);

	const actionFooterVisibility = useMemo(() => {
		switch (careerAppStatus) {
			case CareerAppStatus.hidden:
			case CareerAppStatus.submitted:
				return 'none';
			case CareerAppStatus.submitting:
			case CareerAppStatus.visible:
				return 'block';
			default:
				return 'none';
		}
	}, [careerAppStatus]);

	const footerVisibility = useMemo(() => {
		switch (careerAppStatus) {
			case CareerAppStatus.hidden:
			case CareerAppStatus.submitted:
				return 'block';
			case CareerAppStatus.submitting:
			case CareerAppStatus.visible:
				return 'none';
			default:
				return 'block';
		}
	}, [careerAppStatus]);

	const cleanedFormFields = useMemo<CareerFormData | null>(() => {
		if (positionData) {
			const formFieldsCopy = { ...positionData.formFields };
			// Set jobCategoryId in formFields if it exists in the job opening details
			if (positionData.jobOpening.jobCategoryId) {
				formFieldsCopy.jobCategoryId = positionData.jobOpening.jobCategoryId;
			}

			if (source) {
				formFieldsCopy.sourceId = source;
			}
			if (formFieldsCopy?.countryId && positionData?.jobOpening?.atsLocation?.countryId) {
				formFieldsCopy.countryId.value = positionData.jobOpening?.atsLocation?.countryId?.toString();
			}

			return formFieldsCopy;
		}

		return null;
	}, [positionData, location.search]);

	const defaultActions = useMemo(() => {
		const submitButton = (
			<Button
				className='js-SiteFooter__action--primary'
				color='primary'
				disabled={inTrial}
				key='primary'
				processing={careerAppStatus === CareerAppStatus.submitting}
				size='biggie'
				startIcon='circle-check-regular'
				type='button'
			>
				{$.__('Submit Application')}
			</Button>
		);

		const primaryButton = inTrial ? (
			<Tooltip content={inTrialTooltip}>
				<span className={classes.buttonTooltipWrap}>{submitButton}</span>
			</Tooltip>
		) : (
			submitButton
		);

		const cancelButton = (
			<TextButton className='js-SiteFooter__action--link' key='cancel' type='button'>
				{$.__('Cancel')}
			</TextButton>
		);

		return [primaryButton, cancelButton];
	}, [inTrial, careerAppStatus, classes.buttonTooltipWrap]);

	if (!positionData || !companyInfo) {
		return (
			<div className={sharedClasses.loadingBackground}>
				<Loader />
			</div>
		);
	}

	const { jobOpening } = positionData;
	const queryString = source ? `?source=${source}` : '';

	return (
        <div className={classes.background}>
            <ResponsiveContentWrapper>
				<header className={classes.header}>
					{companyInfo.logoUrl ? (
						<Link className={sharedClasses.imgContainer} to={`/careers${queryString}`}>
							<img alt={companyInfo.name} className={sharedClasses.logoImg} src={companyInfo.logoUrl} />
						</Link>
					) : (
						<Typography variant='h1'>{companyInfo.name}</Typography>
					)}
					<Flex alignItems='center' className={classes.privacyWrapper}>
						<IconV2 color='neutral-medium' name='lock-keyhole-regular' size={12} />
						<a
							className={classes.privacyLink}
							href='http://www.bamboohr.com/privacy.php'
							rel='noopener external nofollow noreferrer'
							target='_blank'
						>
							Privacy Policy
						</a>
					</Flex>
				</header>
				<main className={classes.main}>
					<script dangerouslySetInnerHTML={{ __html: getJobPostingSchema(companyInfo, jobOpening, id) }} type='application/ld+json' />
					{jobOpening.jobOpeningStatus === 'Open' ? (
						<Flex alignItems='flex-start' className={classes.mainContent}>
							<div className={classes.descriptionWrapper}>
								<MainContentCard backUrlString={`/careers${queryString}`} formFields={cleanedFormFields} position={jobOpening} />
							</div>
							<div className={classes.sidebarWrapper}>
								<ApplyCard companyInfo={companyInfo} position={jobOpening}  />
								<PositionInfoList position={jobOpening} />
							</div>
						</Flex>
					) : (
						<BlankPostingCard showLink={true} />
					)}
				</main>
			</ResponsiveContentWrapper>
            <LayoutBox display={actionFooterVisibility}>
                <ActionFooter
                    actions={defaultActions}
                    dark={true}
                    right={<Svg color='neutral-forced-white' height='24px' image='bamboohr-logo' width='156px' />}
                />
            </LayoutBox>
            <LayoutBox display={footerVisibility}>
                <CareerSiteFooter />
            </LayoutBox>
        </div>
    );
}
