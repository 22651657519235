import { IconButton, LayoutBox } from '@bamboohr/fabric';
import React from 'react';
import * as Social from 'Social.mod';
import { ShareType } from './types';

interface ShareButtonProps {
	shareType: ShareType;
	companyInTrial?: boolean;
	shareText?: string;
	viewPositionUrl: string;
	title: string;
}

export const ShareButton = ({
	shareType,
	companyInTrial = false,
	viewPositionUrl,
	title,
	shareText = '',
}: ShareButtonProps): React.ReactElement => {
	const shareIcon = () => {
		switch (shareType) {
			case ShareType.facebook:
				return 'facebook-f';
			case ShareType.twitter:
				return 'twitter';
			case ShareType.linkedin:
				return 'linkedin';
			default:
				return 'up-from-bracket-regular';
		}
	};

	const handleClick = () => {
		switch (shareType) {
			case ShareType.facebook:
				Social.shareFacebook(viewPositionUrl);
				break;
			case ShareType.twitter:
				Social.shareTwitter(viewPositionUrl, { title, shareText });
				break;
			case ShareType.linkedin:
				Social.shareLinkedIn(viewPositionUrl, { shareText });
				break;
			default:
				break;
		}
	};

	return (
        <LayoutBox
			marginX='8px'
		>
            <IconButton
				active={false}
				color='primary'
				disabled={companyInTrial}
				icon={shareIcon()}
				onClick={handleClick}
				rel='noopener noreferrer'
				size='medium'
				variant='outlined'
			/>
        </LayoutBox>
    );
};

ShareButton.defaultProps = {
	companyInTrial: false,
	shareText: '',
};
