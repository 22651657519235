import {
	Button,
	BlankState,
	Section,
	LayoutBox,
} from '@bamboohr/fabric';
import { ReactElement } from 'react';

export default function BlankPostingCard({ showLink }: { showLink: boolean }): ReactElement {

	return (
        <Section>
			<LayoutBox marginY='96px'>
				<BlankState
					actions={
						showLink && [
							<Button color='secondary' href='/careers' variant='contained'>
								{$.__('See Current Openings')}
							</Button>,
						]
					}
					icon='bs-clipboard'
					subtitle={$.__('Please check back as we will most certainly be looking for great people to join our team in the future.')}
					title={$.__('Thank you for your interest. Unfortunately, we are not hiring at this time.')}
				/>
			</LayoutBox>
		</Section>
    );
}
